/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { TableProps } from 'antd';
import TableSimple from 'components/core/table-simple';
import {
  AdminCurrency,
  ExchangePairConfigurationDto,
  FeeConfig,
} from 'api/currency';
import styles from './index.module.css';
import { useUpdatePairModal } from '../update-pair-modal';

type Props = {
  collection: ExchangePairConfigurationDto | null;
  className?: string;
};

type CurrencyValue = {
  spread: string;
  fee: FeeConfig | undefined;
  rate: number | undefined;
} | null;
type ExchangeModel = {
  [key: string]: CurrencyValue;
} & { currency: string };

const ExchangePairTable = observer(({ collection }: Props) => {
  const intl = useIntl();
  const { showUpdatePairModal } = useUpdatePairModal();

  const currencies = collection?.currencies || [];

  const mapCurrencies = currencies.reduce(
    (acc, item) => {
      acc[item.slug] = item;
      return acc;
    },
    {} as Record<string, AdminCurrency>
  );

  const columns: TableProps<ExchangeModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'exchange.columns.currency',
        defaultMessage: 'Currency',
      }),
      dataIndex: 'currency',
      key: 'currency',
      fixed: 'left',
      render: (value: string) => <span>{mapCurrencies[value].shortName}</span>,
    } as const,
    ...currencies.map(({ shortName, slug }) => ({
      title: shortName,
      dataIndex: slug,
      key: slug,
      render: (value: CurrencyValue, record: any) => {
        return (
          <button
            className={styles.cell}
            // style={ value?.rate === undefined ? {
            //   backgroundColor: '#ffff44'
            // }: {}}
            onClick={
              value
                ? () =>
                    showUpdatePairModal(
                      {
                        ...value,
                        from: mapCurrencies[record.currency],
                        to: mapCurrencies[slug],
                      },
                      () => {}
                    )
                : undefined
            }
          >
            {value?.spread && (
              <div>
                <span className={styles.title}>
                  {intl.formatMessage({
                    id: 'exchange.pair.spread',
                    defaultMessage: 'Spread: ',
                  })}
                </span>
                <span className={styles.descr}>{Number(value.spread)}%</span>
              </div>
            )}
            {value?.fee?.feePercent !== undefined && (
              <div>
                <span className={styles.title}>
                  {intl.formatMessage({
                    id: 'exchange.pair.feePercent',
                    defaultMessage: 'Fee: ',
                  })}
                </span>
                <span className={styles.descr}>
                  {Number(value.fee.feePercent) * 100}%
                </span>
              </div>
            )}
            {value?.fee?.feeFixed !== undefined && (
              <div>
                <span className={styles.title}>
                  {intl.formatMessage({
                    id: 'exchange.pair.feeFixed',
                    defaultMessage: 'Fixed fee: ',
                  })}
                </span>
                <span className={styles.descr}>
                  {Number(value.fee.feeFixed)} {record.currency}
                </span>
              </div>
            )}
            {value?.fee?.feeMin !== undefined && (
              <div>
                <span className={styles.title}>
                  {intl.formatMessage({
                    id: 'exchange.pair.feeMin',
                    defaultMessage: 'Min fee: ',
                  })}
                </span>
                <span className={styles.descr}>{Number(value.fee.feeMin)}</span>
              </div>
            )}
            {value?.fee?.feeMax !== undefined && (
              <div>
                <span className={styles.title}>
                  {intl.formatMessage({
                    id: 'exchange.pair.feeMax',
                    defaultMessage: 'Max fee: ',
                  })}
                </span>
                <span className={styles.descr}>{Number(value.fee.feeMax)}</span>
              </div>
            )}
            {(value?.rate !== undefined && (
              <div>
                <span className={styles.title}>
                  {intl.formatMessage({
                    id: 'exchange.pair.rate',
                    defaultMessage: 'Rate: ',
                  })}
                </span>
                <span className={styles.descr}>
                  {Number(value.rate)} {slug}
                </span>
              </div>
            )) ||
              (value?.spread !== undefined && (
                <div>
                  <span className={styles.title}>
                    {intl.formatMessage({
                      id: 'exchange.pair.rate',
                      defaultMessage: 'Rate: ',
                    })}
                  </span>
                  <span
                    className={styles.descr}
                    style={{ color: 'darkred', fontWeight: 'bold' }}
                  >
                    Unavailable
                  </span>
                </div>
              ))}
          </button>
        );
      },
    })),
  ];

  const data = currencies.map(({ slug: fromCurrency }) => {
    const row = { currency: fromCurrency } as ExchangeModel;

    currencies.forEach(({ slug: toCurrency }) => {
      const rate = collection?.exchangePairs?.find(
        (d) =>
          d.fromCurrencySlug === fromCurrency && d.toCurrencySlug === toCurrency
      );
      row[toCurrency] = rate
        ? { spread: rate.spread, fee: rate.fee, rate: rate.rate }
        : null;
    });

    return row;
  });

  return (
    <TableSimple
      className={styles.table}
      size='large'
      rowHoverable={false}
      collection={data}
      columns={columns}
      scroll={{ x: 'max-content', y: 500 }}
    />
  );
});

export default ExchangePairTable;
