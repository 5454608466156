/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import QuestsFilter from './quests-filter';

import { observer } from 'mobx-react-lite';
import {
  ProvideQuestsPageStore,
  useQuestsPageStore,
} from './provider/StoreProvider';
import { useIntl } from 'react-intl';
import { useCallback, useEffect } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import QuestsTable from 'components/tables/quests-table';
import { AdminQuestsModel } from 'models/AdminQuestModel';
import { ROUTES } from 'routes';
import { useNavigate } from 'react-router-dom';

const QuestsPageWithProvider = observer(() => {
  const intl = useIntl();
  const questsStore = useQuestsPageStore();
  const navigate = useNavigate();
  const { layoutStore } = useBaseStores();

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.quests.title',
        defaultMessage: 'Quests',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  const onClick = useCallback(
    (quest: AdminQuestsModel) => {
      navigate(ROUTES.quest.getFullPath(quest.id));
    },
    [navigate]
  );

  return (
    <div className={styles.main}>
      <QuestsFilter collectData={questsStore.quests} />
      <QuestsTable collection={questsStore.quests} onQuestClick={onClick} />
    </div>
  );
});

const QuestsPage = () => {
  return (
    <ProvideQuestsPageStore>
      <QuestsPageWithProvider />
    </ProvideQuestsPageStore>
  );
};

export default QuestsPage;
