/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect } from 'react';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { QuestDetailsPageStore } from '../store/QuestDetailsPageStore';

const storeContext = createContext<QuestDetailsPageStore>(
  {} as QuestDetailsPageStore
);

type Props = {
  id: string;
  children?: React.ReactNode;
};
export const ProviderQuestDetailsPageStore = observer(
  ({ children, id }: Props) => {
    const { questsApi, notificationStore, authApi } = useBaseStores();

    const store = useLocalObservable(
      () => new QuestDetailsPageStore(questsApi, notificationStore, authApi)
    );

    useEffect(() => {
      store.init(id);
    }, [store, id]);

    return (
      <storeContext.Provider value={store}>{children}</storeContext.Provider>
    );
  }
);

export const useQuestDetailsPageStore = () => {
  return useContext(storeContext);
};
