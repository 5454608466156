import type { Identifier, XYCoord } from 'dnd-core';
import type { FC } from 'react';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ReactComponent as DragIcon } from 'assets/images/icons/drag.svg';
import styles from './index.module.css';
import Button from 'components/core/button';
import { useIntl } from 'react-intl';
import { QuestStepEvents } from 'api/quests';
import {
  transformFromBackendTimer,
  transformToBackendTimer,
} from 'utils/dates';
import cn from 'classnames';
import { isInstallEvent } from '../../index';

export type EventProps = {
  index: number;
  id: string;
  questCurrency?: string;
  moveCard?: (dragIndex: number, hoverIndex: number) => void;
  onDelete: () => void;
  event: QuestStepEvents & isInstallEvent;
};

type DragItem = {
  index: number;
  id: string;
  type: string;
};

export const Event: FC<EventProps> = ({
  index,
  moveCard,
  onDelete,
  event,
  id,
  questCurrency,
}) => {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'event',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current || !moveCard || event.isInstall) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'event',
    item: () => {
      return { id, index };
    },
    canDrag: !event.isInstall, // Запрещаем перетаскивание, если isInstall = true
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  preview(drop(ref));

  return (
    <div
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      className={cn(styles.container, event.isInstall && '!pl-10')}
    >
      <div className={styles.wrapper}>
        {!event.isInstall && (
          <button ref={drag}>
            <DragIcon />
          </button>
        )}

        <div className={styles.content}>
          <div className={styles.col}>
            <span className={styles.name}>{event.name}</span>
            {event.exclusive && (
              <span className={styles.exclusive}>
                {intl.formatMessage({
                  id: 'sidebar.create_quest.exclusive_event',
                  defaultMessage: 'Exclusive event',
                })}
              </span>
            )}
          </div>
          <div className={styles.row}>
            <span className={styles.label}>
              {intl.formatMessage({
                id: 'sidebar.create_quest.event_timer',
                defaultMessage: 'Event timer',
              })}
            </span>
            <span className={styles.value}>
              {
                transformFromBackendTimer(
                  transformToBackendTimer(event.timer?.toString())
                ).ui
              }
            </span>
          </div>
          <div className={styles.row}>
            <span className={styles.label}>
              {intl.formatMessage({
                id: 'sidebar.create_quest.reward',
                defaultMessage: 'reward',
              })}
            </span>
            <span className={styles.value}>
              {event.reward || 0}&nbsp;
              {questCurrency || '$'}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        {!event.isInstall && (
          <Button size='small' variant='secondary' onClick={onDelete}>
            {intl.formatMessage({
              id: 'sidebar.create_event.delete',
              defaultMessage: 'Delete',
            })}
          </Button>
        )}
      </div>
    </div>
  );
};
