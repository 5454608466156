/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import dayjs from 'dayjs';

export const DATE_FORMAT = 'DD MMMM HH:mm:ss';
export const FULL_DATE = 'DD/MM/YYYY';

export const unixTsToDateStr = (timestamp: number, dateFormat: string) => {
  return dayjs.unix(timestamp).format(dateFormat);
};

export const formattedDate = (
  val: string | number,
  dateFormat = 'DD.MM.YYYY HH:mm'
) => {
  const date = dayjs(val);
  return date.isValid() ? date.format(dateFormat) : '-';
};

export const getUnixTsNow = () => dayjs().unix();

export const getRemainingDate = (duration: number) => {
  // Calculate the number of days
  const days = Math.floor(duration / (24 * 60 * 60));
  // Calculate the number of hours after subtracting days
  const hours = Math.floor((duration % (24 * 60 * 60)) / (60 * 60));
  // Calculate the number of minutes after subtracting hours
  const mins = Math.floor((duration % (60 * 60)) / 60);

  // Build the result array with days, hours, and minutes
  const result = [
    { value: days, descr: 'd' },
    { value: hours, descr: 'h' },
    { value: mins, descr: 'm' },
  ]
    .filter((item) => item.value) // Remove zero values
    .map((item) => `${item.value}${item.descr}`) // Format as "<value><descr>"
    .join(' '); // Join results with a space

  // Return the result or "0m" if no time components are present
  return result || '0m';
};

export const transformToBackendTimer = (value?: string | undefined) => {
  if (!value) return undefined;

  const paddedValue = value.padStart(8, '0');

  const [d1, d2, h1, h2, m1, m2, s1, s2] = paddedValue;

  const dayInSecondes = 24 * 60 * 60 * Number(`${d1}${d2}`);
  const hoursInSecondes = 60 * 60 * Number(`${h1}${h2}`);
  const minsInSecondes = 60 * Number(`${m1}${m2}`);

  return (
    dayInSecondes + Number(`${s1}${s2}`) + hoursInSecondes + minsInSecondes
  );
};

const formatTimeUnit = (unit: number): string => {
  return unit < 10 ? `0${unit}` : `${unit}`;
};

export const transformFromBackendTimer = (seconds: number | undefined) => {
  if (seconds === undefined) {
    return {
      value: undefined,
      ui: '-',
    };
  }

  const days = Math.floor(seconds / (24 * 3600));
  seconds %= 24 * 3600;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;

  return {
    value: `${formatTimeUnit(days)}${formatTimeUnit(hours)}${formatTimeUnit(
      minutes
    )}${formatTimeUnit(secs)}`,
    ui: `${formatTimeUnit(days)}:${formatTimeUnit(hours)}:${formatTimeUnit(
      minutes
    )}:${formatTimeUnit(secs)}`,
  };
};

export const calculateAge = (birthDate: string): string | number => {
  const birthDay = dayjs(birthDate, 'DD-MM-YYYY');

  if (!birthDay.isValid()) {
    return '-';
  }

  const today = dayjs();
  const age = today.diff(birthDay, 'year');

  return age;
};
