import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import styles from './index.module.css';
import Button from 'components/core/button';
import { useBaseStores } from 'providers/BaseStoresProvider';

const ConfirmCloseQuest: FC = () => {
  const { formatMessage } = useIntl();
  const { layoutStore } = useBaseStores();

  const closeModal = () => {
    layoutStore.toggleModal(false);
  };

  const exitForm = () => {
    layoutStore.toggleModal(false);
    layoutStore.toggleSidebar(false);
  };

  return (
    <div className={styles.wrapper}>
      <span>
        {formatMessage({
          id: 'sidebar.create_quest.confirm_modal.question',
          defaultMessage: `Are you sure you want to exit the quest creation form? Unsaved changes will be lost.`,
        })}
      </span>

      <div className={styles.btns}>
        <Button
          className={styles.btn}
          variant="secondary"
          onClick={closeModal}
        >
          {formatMessage({
            id: 'sidebar.create_quest.confirm_modal.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>

        <Button className={styles.btn} variant="primary" onClick={exitForm}>
          {formatMessage({
            id: 'sidebar.create_quest.confirm_modal.exit',
            defaultMessage: 'Exit form',
          })}
        </Button>
      </div>
    </div>
  );
};

export const useConfirmCloseQuest = () => {
  const { formatMessage, locale } = useIntl();
  const { layoutStore } = useBaseStores();

  const showConfirmCloseModal = useCallback(
    () => {
      layoutStore.toggleModal(
        true,
        formatMessage({
          id: 'sidebar.create_quest.confirm_modal.title',
          defaultMessage: 'Exit Without Saving',
        }),
        <ConfirmCloseQuest />,
      );
    },
    [locale, layoutStore],
  );

  return {
    showConfirmCloseModal,
  };
};
