import React, { FC } from 'react';

import styles from './index.module.css';
import classNames from 'classnames';

type InfoBoxProps = {
  title: string;
  value: string;
  className?: string;
};

export const InfoBox: FC<InfoBoxProps> = ({ title, value, className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <span className={styles.title}>{title}</span>
      <span className={styles.value}>{value}</span>
    </div>
  );
};
