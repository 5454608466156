/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import {
  CreateQuest,
  PlatformType,
  QuestStatus,
  QuestStepEvents,
  TimerType,
} from 'api/quests';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useIntlValidation } from 'hooks/intl/validation';
import joi from 'joi';
import { debounce } from 'lodash';
import { AdminQuestsModel } from 'models/AdminQuestModel';
import { SelectItemModel } from 'models/form/SelectItemModel';
import { CreateModal } from './CreateModal';
import { EditEvents } from './EditQuests';
import { UploadFile } from 'antd';
import { transformFromBackendTimer } from 'utils/dates';

export type isInstallEvent = { isInstall?: boolean };

export type CreateQuestForm = {
  name: string;
  description: string;
  id: string;
  reward: number;
  budget: number;
  cpi: number;
  delay: number;
  templateUrl: string;
  currency: string;
  events: (QuestStepEvents & isInstallEvent)[];
  startedAt: Date;
  expiredAt: Date;
  accountId: string;
  status: QuestStatus;
  image: UploadFile;
  active: boolean;
  platforms: SelectItemModel<string>[];
  timerType: SelectItemModel<string>;
  timer?: number;
};

const enum Step {
  Create,
  EditEvents,
}

const platforms = [
  {
    id: PlatformType.ANDROID,
    label: 'Android',
    value: PlatformType.ANDROID,
  },
  {
    id: PlatformType.WEB,
    label: 'Web',
    value: PlatformType.WEB,
  },

  {
    id: PlatformType.IOS,
    label: 'IOS',
    value: PlatformType.IOS,
  },
];

type Props = {
  quest?: AdminQuestsModel;
  onCreate: (quest: CreateQuest) => Promise<void>;
  onDelete?: (questId: string) => void;
  onClose?: () => void;
};

const SidebarQuestCreate = observer(({ quest, onCreate, onDelete }: Props) => {
  const intl = useIntl();

  const { accountsStore } = useBaseStores();
  const [step, setStep] = useState(Step.Create);

  const { validationOptions } = useIntlValidation();

  const formScheme = useMemo(
    () =>
      joi.object<CreateQuestForm>({
        id: joi.string(),
        timer: joi.string().empty(''),
        events: joi.any(),
        name: joi.string().empty('').required(),
        accountId: joi.string().empty('').required(),
        currency: joi.string().empty('').required(),
        templateUrl: joi
          .string()
          .pattern(/\{userid\}/)
          .pattern(/\{questid\}/)
          .custom((value, helpers) => {
            if (
              !value.includes('{cost_hash}') &&
              !value.includes('{cost_value}')
            ) {
              return helpers.error('string.costPlaceholder');
            }
            return value;
          })
          .required()
          .messages({
            'string.pattern.base':
              'The link must contain the required placeholders:{userid} \\{userid\\}, \\{questid\\}',
            'string.costPlaceholder':
              'The link must contain either {userid}  \\{cost_hash\\} or \\{cost_value\\}',
            'any.required': 'Quest link is required',
          }),
        status: joi.string(),
        description: joi.string().empty('').max(1000),
        timerType: joi.object().required(),
        image: quest ? joi.object().empty(undefined) : joi.object().required(),
        platforms: joi.array().min(1).required(),
        active: joi.boolean(),
        budget: joi.number().empty('').min(0).max(Number.MAX_SAFE_INTEGER),
        reward: joi.number().empty('').min(0).max(Number.MAX_SAFE_INTEGER),
        delay: joi.number().min(0).max(Number.MAX_SAFE_INTEGER).required(),
        cpi: joi
          .number()
          .empty('')
          .min(0)
          .max(Number.MAX_SAFE_INTEGER)
          .required(),
        startedAt: joi.date().required(),
        expiredAt: joi
          .date()
          .required()
          .when('startedAt', {
            is: joi.exist(),
            then: joi.date().greater(joi.ref('startedAt')).messages({
              'date.greater': 'The End Date must be greater than Start Date',
            }),
          }),
      }),
    [quest]
  );

  const timersTypes = [
    {
      id: TimerType.UNIVERSAL,
      label: intl.formatMessage({
        id: 'sidebar.create_quest.universal',
        defaultMessage: 'Universal timer',
      }),
      value: TimerType.UNIVERSAL,
    },
    {
      id: TimerType.SEPARATE,
      label: intl.formatMessage({
        id: 'sidebar.create_quest.separate',
        defaultMessage: 'Separate timer',
      }),
      value: TimerType.SEPARATE,
    },
  ];

  const form = useForm<CreateQuestForm>({
    resolver: joiResolver(formScheme, validationOptions),
    mode: 'onChange',
    defaultValues: quest
      ? {
          id: quest.id,
          name: quest.name,
          description: quest.description,
          reward: quest.reward,
          budget: quest.budget,
          delay: quest.delay,
          cpi: quest.cpi,
          platforms:
            quest.platforms?.map((value) =>
              platforms.find(({ value: v }) => value === v)
            ) || [],
          templateUrl: quest.templateUrl,
          currency: quest.currency,
          accountId: quest.accountId,
          startedAt: quest.startedAt ? new Date(quest.startedAt) : undefined,
          expiredAt: quest.expiredAt ? new Date(quest.expiredAt) : undefined,
          status: quest.status,
          active: quest.active,
          timerType: timersTypes.find(({ value }) => value === quest.timerType),
          timer: transformFromBackendTimer(quest.timer).value as any,
          events:
            quest.questSteps?.map((event) => ({
              ...event,
              timer: transformFromBackendTimer(event.timer || undefined)
                .value as any,
              //@ts-ignore
              eventId: event.events?.[0]?.event_id,
              //@ts-ignore
              isInstall: event.events?.[0]?.event_id === 'install',
            })) || [],
        }
      : {
          events: [
            {
              name: 'Install',
              //@ts-ignore
              eventId: '',
              events: [{ event_id: 'install' }],
              exclusive: false,
              timer: undefined,
              reward: undefined,
              isInstall: true,
              position: 0,
            },
          ],
          reward: 0,
          active: true,
          timerType: timersTypes[0],
        },
  });
  const { control, resetField, setValue, watch } = form;

  useEffect(() => {
    const subscription = watch((formValues) => {
      console.log('Form State Changed:', formValues);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const [accountId, timerType, timer, questEvents] = useWatch({
    control,
    name: ['accountId', 'timerType', 'timer', 'events'],
  });

  useEffect(() => {
    if (timerType.value === TimerType.SEPARATE) {
      form.setValue('timer', undefined);
    }
  }, [timerType.value, form]);

  const loadBalances = useMemo(
    () => debounce(accountsStore.accountBalances.fetch, 500),
    [accountsStore.accountBalances]
  );

  useEffect(() => {
    if (accountId) loadBalances(accountId);

    return () => {
      accountsStore.accountBalances.reset();
      resetField('currency');
    };
  }, [loadBalances, accountId, accountsStore.accountBalances, resetField]);

  const onEditEvent = () => {
    setStep(Step.EditEvents);
  };

  const onSaveEvents = (events: (QuestStepEvents & isInstallEvent)[]) => {
    setStep(Step.Create);
    setValue('events', events);
  };

  return (
    <FormProvider {...form}>
      {step === Step.Create && (
        <CreateModal
          quest={quest}
          onCreate={onCreate}
          onDelete={onDelete}
          onEditEvent={onEditEvent}
        />
      )}
      {step === Step.EditEvents && (
        <EditEvents
          isUniversal={timerType.value === TimerType.UNIVERSAL}
          isEdit={!!quest}
          timer={timer}
          onSave={onSaveEvents}
          events={questEvents || []}
          onBack={() => setStep(Step.Create)}
        />
      )}
    </FormProvider>
  );
});

export const useSidebarCreateQuest = ({
  quest,
  onCreate,
  onDelete,
  onClose,
}: Props) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(() => {
    layoutStore.toggleSidebar(
      true,
      intl.formatMessage({
        id: 'sidebar.quest',
        defaultMessage: quest ? 'Edit quest info' : 'Create a quest',
      }),
      <SidebarQuestCreate
        quest={quest}
        onCreate={onCreate}
        onDelete={onDelete}
      />,
      onClose
    );
  }, [quest, intl, layoutStore, onCreate, onDelete]);

  return {
    showCreateQuestSidebar: showSidebar,
  };
};
