/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import classNames from 'classnames';

import React, { useCallback, useEffect, useState } from 'react';

export type InputFieldProps = {
  value?: string | number;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  error?: string;
  icon?: React.ReactNode;
  variant?: 'primary' | 'secondary';
  isFormatted?: boolean;
};
const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      value,
      placeholder,
      type,
      disabled,
      onChange,
      onFocus,
      onBlur,
      className,
      error,
      icon,
      variant,
      isFormatted,
    },
    ref
  ) => {
    const [inputValue, setStrValue] = useState(value);

    const handleInput = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setStrValue(event.target.value);

        //@ts-ignore
        onChange?.(isFormatted ? event : event.target.value);
      },
      [onChange, isFormatted]
    );

    useEffect(() => {
      if (inputValue !== value) {
        setStrValue(value);
      }
    }, [inputValue, value]);

    return (
      <div
        className={classNames(
          styles.main,
          {
            [styles.secondary]: variant === 'secondary',

            [styles.primary]: !variant || variant === 'primary',
          },
          className
        )}
      >
        <div
          className={classNames(styles.container, {
            [styles.disabled]: disabled,
          })}
        >
          {icon ?? null}

          <input
            ref={ref}
            className={styles.input}
            value={value ?? ''}
            placeholder={placeholder}
            type={type ?? 'text'}
            onChange={handleInput}
            onFocus={onFocus}
            onBlur={onBlur}
            autoComplete='off'
            disabled={disabled}
          />
        </div>

        {error && <span className={styles.error}>{error}</span>}
      </div>
    );
  }
);

export default InputField;
