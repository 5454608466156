import type { FC } from 'react';
import { useCallback } from 'react';

import { Event } from './Event';
import {
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { CreateQuestForm } from '..';
import styles from './index.module.css';
import Button from 'components/core/button';
import { useIntl } from 'react-intl';
import { MdEdit } from 'react-icons/md';
import { QuestStatus } from 'api/quests';

export type Item = {
  id: number;
  text: string;
};

export type ContainerState = {
  cards: Item[];
};

type EventsProps = {
  onEditEvent: () => void;
};

export const Events: FC<EventsProps> = ({ onEditEvent }) => {
  const intl = useIntl();
  const { control, setValue, getValues, watch } =
    useFormContext<CreateQuestForm>();

  const { move, fields, remove } = useFieldArray<CreateQuestForm>({
    name: 'events',
    control,
  });

  const [questCurrency] = watch(['currency']);

  const isEditEventsAllowed =
    getValues('status') === QuestStatus.NEW ||
    getValues('status') === undefined;

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      // Create a copy of the current events
      const currentEvents = [...getValues('events')];

      // Move the event in the array
      const [removedEvent] = currentEvents.splice(dragIndex, 1);
      currentEvents.splice(hoverIndex, 0, removedEvent);

      // Update positions for all events
      const updatedEvents = currentEvents.map((event, index) => ({
        ...event,
        position: index,
      }));

      // Update the form values
      setValue('events', updatedEvents);
    },
    [getValues, setValue]
  );

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.wrapper}>
          <h4>Events</h4>
          <span className={styles.description}>In order of execution</span>
        </div>
        <Button
          className={styles.button}
          size='medium'
          variant='secondary'
          onClick={onEditEvent}
          disabled={!isEditEventsAllowed || !questCurrency}
        >
          {intl.formatMessage({
            id: 'sidebar.create_event.edit_event',
            defaultMessage: 'Edit event',
          })}
          <MdEdit />
        </Button>
      </div>
      <div
        className={styles.list}
        style={{
          pointerEvents: !isEditEventsAllowed ? 'none' : 'auto',
        }}
      >
        {fields.map((event, i) => (
          <Event
            key={event.id}
            index={i}
            id={event.id}
            event={event as FieldArrayWithId<CreateQuestForm, 'events'>}
            moveCard={moveCard}
            onDelete={() => remove(i)}
            questCurrency={questCurrency}
          />
        ))}
      </div>
    </div>
  );
};
