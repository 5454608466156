/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import Button from 'components/core/button';
import { Switch, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useCallback, useState } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useSetQueryParams } from 'hooks/collection-query-params';
import { CreateQuest, PlatformType, QuestStatus, TimerType } from 'api/quests';
import DateTimePicker, { DatePickerSize } from 'components/core/datepicker';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import FormField from 'components/form/form-field';
import FormSelect from 'components/form/form-select';
import { AdminQuestsModel } from 'models/AdminQuestModel';
import { Events } from '../Events';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDeleteQuest } from '../DeleteModal';
import { BadgeStatus } from 'entities/quest';
import { PatternFormat } from 'react-number-format';
import InputField from 'components/core/input-field';
import FormListMultiSelect from 'components/form/form-list-multi-select';
import FormListSingleSelect from 'components/form/form-list-single-select';
import { CreateQuestForm } from '..';
import { transformToBackendTimer } from 'utils/dates';
import { Banner } from './Banner';
import { useTimerTypeSynchronization } from './useTimerTypeSynchronization';

type Props = {
  quest?: AdminQuestsModel;
  onCreate: (quest: CreateQuest) => Promise<void>;
  onDelete?: (questId: string) => void;
  onEditEvent: () => void;
};

export const CreateModal = observer(
  ({ quest, onCreate, onDelete, onEditEvent }: Props) => {
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false);

    useTimerTypeSynchronization();

    const { layoutStore, accountsStore, currencyStore } = useBaseStores();
    const { setFilterQueryParams } = useSetQueryParams();

    const accounts = accountsStore.accounts.map((id) => ({
      id: id,
      value: id,
      label: id,
    }));

    const timersTypes = [
      {
        id: TimerType.UNIVERSAL,
        label: intl.formatMessage({
          id: 'sidebar.create_quest.universal',
          defaultMessage: 'Universal timer',
        }),
        value: TimerType.UNIVERSAL,
      },
      {
        id: TimerType.SEPARATE,
        label: intl.formatMessage({
          id: 'sidebar.create_quest.separate',
          defaultMessage: 'Separate timer',
        }),
        value: TimerType.SEPARATE,
      },
    ];

    const { handleSubmit, control, reset } = useFormContext<CreateQuestForm>();

    const onSubmit = useCallback(
      async ({
        timer,
        events,
        timerType,
        platforms,
        image,
        ...values
      }: CreateQuestForm) => {
        setIsLoading(true);

        const reward = events.reduce(
          (acc, { reward }) => Number(reward || 0) + acc,
          0
        );

        const handleFileToBase64 = (file: any) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result); // Base64 строка
            reader.onerror = (error) => reject(error);
          });
        };
        const base64 = image?.originFileObj
          ? await handleFileToBase64(image.originFileObj)
          : undefined;
        try {
          await onCreate({
            ...values,
            steps: events.map((item) => ({
              ...item,
              reward: Number(item.reward || 0),
              timer: transformToBackendTimer(
                item?.timer === 0 ? undefined : item?.timer?.toString()
              ),
            })),
            startedAt: values.startedAt.toISOString(),
            expiredAt: values.expiredAt.toISOString(),
            timer:
              timer === 0 || timer?.toString() === '00000000'
                ? undefined
                : transformToBackendTimer(timer?.toString()),
            timerType: timerType.id as TimerType,
            platforms: platforms.map(({ id }) => id) as PlatformType[],
            countries: [],
            reward,
            budget: values.budget ?? undefined,
            image: base64
              ? {
                  image: base64 as string,
                  name: image.name,
                  type: image.type as any,
                }
              : undefined,
          });
          setIsLoading(false);
          reset();
          layoutStore.toggleSidebar(false);
        } catch (error) {
          console.error(error);
        }
        setIsLoading(false);
      },
      [layoutStore, setFilterQueryParams, onCreate, reset]
    );

    const handleDelete = async () => {
      if (!quest) return;
      onDelete?.(quest.id);
      reset();
      layoutStore.toggleSidebar(false);
    };

    const { showDeleteQuestModal } = useDeleteQuest();

    const [startedAt, expiredAt, timerType, events] = useWatch<CreateQuestForm>(
      {
        control,
        name: ['startedAt', 'expiredAt', 'timerType', 'events'],
      }
    );

    const totalReward = Number(
      events
        .reduce(
          (acc: number, { reward }: CreateQuestForm) =>
            acc + Number(reward || 0),
          0
        )
        .toFixed(2)
    );

    const selectOptions = currencyStore.currencies.map((item) => ({
      id: item.id,
      value: item.id,
      label: item.id,
      icon: item?.iconUrl,
    }));

    return (
      <>
        <div className={styles['status-container']}>
          <Controller
            control={control}
            name='active'
            render={({ field: { value, onChange } }) => (
              <Tooltip
                title={intl.formatMessage({
                  id: 'sidebar.create_quest.quest_status_tooltip',
                  defaultMessage: value ? 'Quest is active' : 'Quest is paused',
                })}
              >
                <Switch
                  value={value}
                  onChange={(v) => onChange(v)}
                  size='small'
                />
              </Tooltip>
            )}
          />
          {quest && <BadgeStatus status={quest.status as QuestStatus} />}
        </div>

        <div className={styles.container}>
          <Controller
            control={control}
            name='name'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.create_quest.name',
                  defaultMessage: 'Name',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.create_quest.name.placeholder',
                  defaultMessage: 'Quest name that the user will see',
                })}
                variant='secondary'
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name='accountId'
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormSelect
                  label={intl.formatMessage({
                    id: 'sidebar.create_quest.account_id',
                    defaultMessage: 'Account ID',
                  })}
                  variant='borderless'
                  options={accounts}
                  placeholder={intl.formatMessage({
                    id: 'sidebar.create_quest.account_id',
                    defaultMessage: 'Account ID',
                  })}
                  onChange={(value) => onChange(value?.value as string)}
                  value={accounts.find((item) => item.value === value)}
                  error={error?.message}
                />
              );
            }}
          />
          <Controller
            control={control}
            name='currency'
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormSelect
                  label={intl.formatMessage({
                    id: 'sidebar.create_quest.currency',
                    defaultMessage: 'Currency',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'sidebar.create_quest.currency.placeholder',
                    defaultMessage: 'Currency',
                  })}
                  variant='borderless'
                  options={selectOptions}
                  value={selectOptions.find((item) => item.value === value)}
                  onChange={(value) => onChange(value?.value as string)}
                  error={error?.message}
                />
              );
            }}
          />
          <Controller
            control={control}
            name='description'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.create_quest.description',
                  defaultMessage: 'Description',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.create_quest.description.placeholder',
                  defaultMessage:
                    'Description of the quest that the user will see',
                })}
                type='text_area'
                variant='secondary'
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <Banner image={quest?.image} />

          <div className={styles.two_cols}>
            <div className={styles.col}>
              <h5>
                {intl.formatMessage({
                  id: 'sidebar.create_quest.start',
                  defaultMessage: 'Start',
                })}
              </h5>
              <Controller
                control={control}
                name='startedAt'
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <DateTimePicker
                    allowClear={false}
                    placeholder='Date and time'
                    dateFormat={{
                      format: 'YYYY-MM-DD HH:mm:ss',
                      type: 'mask',
                    }}
                    size={DatePickerSize.Large}
                    onChange={onChange}
                    value={value ? new Date(value) : undefined}
                    maxDate={expiredAt}
                    error={error?.message}
                  />
                )}
              />
            </div>
            <div className={styles.col}>
              <h5>
                {intl.formatMessage({
                  id: 'sidebar.create_quest.end',
                  defaultMessage: 'End',
                })}
              </h5>
              <Controller
                control={control}
                name='expiredAt'
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <DateTimePicker
                    allowClear={false}
                    placeholder='Date and time'
                    dateFormat={{
                      format: 'YYYY-MM-DD HH:mm:ss',
                      type: 'mask',
                    }}
                    size={DatePickerSize.Large}
                    onChange={onChange}
                    value={value ? new Date(value) : undefined}
                    minDate={startedAt}
                    error={error?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className={styles.col}>
            <div className={styles['timer-container']}>
              <h5>
                {intl.formatMessage({
                  id: 'sidebar.create_quest.quest_timer',
                  defaultMessage: 'Quest timer',
                })}
              </h5>
              <Controller
                control={control}
                name='timerType'
                render={({ field: { value, onChange } }) => (
                  <FormListSingleSelect
                    title=''
                    className={styles.timer}
                    options={timersTypes}
                    selected={value}
                    onSelect={onChange}
                  />
                )}
              />
            </div>

            <Controller
              control={control}
              name='timer'
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <PatternFormat
                  disabled={timerType.value === TimerType.SEPARATE}
                  customInput={InputField}
                  format='##:##:##:##'
                  /* allowEmptyFormatting*/
                  placeholder='DD:HH:MM:SS'
                  mask={['D', 'D', 'H', 'H', 'M', 'M', 'S', 'S']}
                  isFormatted
                  variant='secondary'
                  value={value || ''}
                  onValueChange={(e) => {
                    onChange(e.value);
                  }}
                  onBlur={() => {
                    if (!value) return;

                    const newValue = Array.from({ length: 8 })
                      .map((_, index) => value.toString()[index] || '0')
                      .join('');

                    onChange(newValue);
                  }}
                  error={error?.message}
                />
              )}
            />
          </div>

          <Controller
            control={control}
            name='platforms'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div className={styles.col}>
                <FormListMultiSelect
                  title={intl.formatMessage({
                    id: 'sidebar.create_quest.platforms',
                    defaultMessage: 'Platforms',
                  })}
                  options={[
                    {
                      id: PlatformType.ANDROID,
                      label: 'Android',
                      value: PlatformType.ANDROID,
                    },
                    {
                      id: PlatformType.WEB,
                      label: 'Web',
                      value: PlatformType.WEB,
                    },

                    {
                      id: PlatformType.IOS,
                      label: 'IOS',
                      value: PlatformType.IOS,
                    },
                  ]}
                  selected={value}
                  onSelect={onChange}
                />
                {error?.message && (
                  <span className={styles.error}>{error?.message}</span>
                )}
              </div>
            )}
          />

          <DndProvider backend={HTML5Backend}>
            <Events onEditEvent={onEditEvent} />
          </DndProvider>

          <Controller
            control={control}
            name='delay'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.create_quest.delay',
                  defaultMessage: 'Delay between events',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.create_quest.delay_between_events.placeholder',
                  defaultMessage: '60s',
                })}
                variant='secondary'
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <div className={styles.three_cols}>
            <Controller
              control={control}
              name='reward'
              render={({ fieldState: { error } }) => (
                <FormField
                  disabled
                  label={intl.formatMessage({
                    id: 'sidebar.create_quest.reward',
                    defaultMessage: 'Reward',
                  })}
                  placeholder='60 USDT'
                  variant='secondary'
                  value={totalReward}
                />
              )}
            />

            <Controller
              control={control}
              name='budget'
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <FormField
                  label={intl.formatMessage({
                    id: 'sidebar.create_quest.budget',
                    defaultMessage: 'Budget',
                  })}
                  tooltip='You can leave this field blank if you don’t want to set a budget limit.'
                  placeholder='$100'
                  variant='secondary'
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
            <Controller
              control={control}
              name='cpi'
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <FormField
                  label={intl.formatMessage({
                    id: 'sidebar.create_quest.cpi',
                    defaultMessage: 'CPI',
                  })}
                  placeholder='$10'
                  variant='secondary'
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>

          <Controller
            control={control}
            name='templateUrl'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.create_quest.link',
                  defaultMessage: 'Quest link',
                })}
                placeholder='https://www.test.com/?quest={userid}/{questid}&af_enc_data={cost_hash}&af_click_lookback=7d'
                variant='secondary'
                value={value}
                onChange={onChange}
                error={error?.message}
                tooltip={intl.formatMessage({
                  id: 'sidebar.create_quest.link.tooltip',
                  defaultMessage:
                    'The link must include a valid URL with userid, questid, and cost_hash or cost_value placeholders',
                })}
              />
            )}
          />
        </div>
        {quest ? (
          <div className={styles.buttons}>
            <Button
              className={styles.apply_btn}
              variant='negative'
              onClick={() => showDeleteQuestModal(handleDelete)}
            >
              {intl.formatMessage({
                id: 'sidebar.quest_create.delete',
                defaultMessage: 'Delete quest',
              })}
            </Button>
            <Button
              className={styles.apply_btn}
              variant='primary'
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              {intl.formatMessage({
                id: 'sidebar.quest_create.save',
                defaultMessage: 'Save changes',
              })}
            </Button>
          </div>
        ) : (
          <Button
            className={styles.apply_btn}
            variant='primary'
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {intl.formatMessage({
              id: 'sidebar.quest_create.create',
              defaultMessage: 'Create',
            })}
          </Button>
        )}
      </>
    );
  }
);
