/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import classNames from 'classnames';

import { observer } from 'mobx-react-lite';
import { ReactComponent as CloseIconSvg } from 'assets/images/icons/close-big.svg';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useCallback } from 'react';

const LayoutSidebar = observer(() => {
  const { layoutStore } = useBaseStores();

  const close = useCallback(() => {
    layoutStore.sidebarData.onClose
      ? layoutStore.sidebarData.onClose()
      : layoutStore.toggleSidebar(false);
  }, [layoutStore]);

  return (
    <div>
      {layoutStore.sidebarData.show && (
        <div className={styles.overlay} onClick={close} />
      )}

      <div
        className={classNames(styles.container, {
          [`${styles.open}`]: !!layoutStore.sidebarData.show,
        })}
      >
        <div className={styles.content}>
          <div className={styles.body}>
            <div className={styles.head}>
              <h4>{layoutStore.sidebarData.title}</h4>

              <button className={styles.close_btn} onClick={close}>
                <CloseIconSvg />
              </button>
            </div>

            {layoutStore.sidebarData.content}
          </div>
        </div>
      </div>
    </div>
  );
});

export default LayoutSidebar;
