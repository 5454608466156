/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import FormLabel, { FormLabelType } from 'components/form/form-label';
import { ReactComponent as InfoIcon } from 'assets/images/icons/info.svg';
import InputField, { InputFieldProps } from 'components/core/input-field';
import TextAreaField from 'components/core/text-area-field';
import React from 'react';
import { Tooltip } from 'antd';

const FormField = React.forwardRef(
  (
    props: InputFieldProps & {
      label?: string;
      tooltip?: string;
      labelType?: FormLabelType;
      type?: 'input' | 'text_area';
      required?: boolean;
    },
    ref
  ) => {
    return (
      <div className={styles.main}>
        {!!props.label && (
          <div className={styles.box}>
            <FormLabel
              text={props.label}
              required={props.required}
              type={props.labelType}
            />
            {props.tooltip && (
              <Tooltip title={props.tooltip}>
                <InfoIcon width={18} color='red' />
              </Tooltip>
            )}
          </div>
        )}

        {(!props.type || props.type === 'input') && (
          <InputField variant='primary' {...props} ref={ref as any} />
        )}

        {props.type === 'text_area' && (
          <TextAreaField variant='primary' {...props} />
        )}
      </div>
    );
  }
);

export default FormField;
