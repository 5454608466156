/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { AuthApi, QuestsApi } from 'api';
import { CreateQuest } from 'api/quests';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { makeAutoObservable, runInAction } from 'mobx';
import { AdminQuestsModel } from 'models/AdminQuestModel';
import { UserQuestsModel } from 'models/UserQuestModel';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { NotificationStore } from 'stores/NotificationStore';

export class QuestDetailsPageStore {
  constructor(
    private questApi: QuestsApi,
    private notificationStore: NotificationStore,
    private authApi: AuthApi
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _quest: AdminQuestsModel | null = null;
  get quest() {
    return this._quest;
  }

  async updateQuest(quest: CreateQuest) {
    try {
      const result = await this.questApi.adminQuest.updateQuest(quest);
      this.notificationStore.notify({
        title: 'Quest was updated',
        type: 'success',
      });
      runInAction(() => {
        this._quest = new AdminQuestsModel(result.data);
      });
    } catch (error) {
      this.notificationStore.handleApiError(error);
      console.error(error);
    }
  }
  async deleteQuest(questId: string) {
    try {
      await this.questApi.adminQuest.deleteQuest(questId);
      this.notificationStore.notify({
        title: 'Quest was deleted',
        type: 'success',
      });
    } catch (error) {
      this.notificationStore.handleApiError(error);
      console.error(error);
    }
  }

  private _users = new CollectionWithPages<
    UserQuestsModel,
    { page?: number; size?: number }
  >({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async (params) => {
      const result =
        await this.questApi.adminQuest.getUserQuestByQuestIdAndIdPage(
          {
            ...params,
            questId: this._quest?.id!,
          },
          {
            paramsSerializer: {
              indexes: null,
            },
          }
        );

      //const items = result.data.data.map((x) => new UserQuestsModel(x));

      // Parallel loading of additional user information
      const userDetailsPromises = result.data.data.map(async (x) => {
        try {
          // Get user details and platforms in parallel
          const [userDetails, platformsResponse] = await Promise.all([
            this.authApi.admin.adminGetUser(x.userId),
            this.questApi.adminQuest.getUserPlatformsByUserId(x.userId),
          ]);

          return {
            ...new UserQuestsModel({
              ...x,
              userEmail:
                userDetails.data?.individual?.emailAddress ||
                userDetails.data.email,
              userAge: userDetails.data?.individual?.dateOfBirth,
              userGender: userDetails.data?.individual?.meta?.sex,
              platforms: platformsResponse.data?.platforms,
            }),
          };
        } catch (error) {
          // In case of an error, return the base model without additional data
          return new UserQuestsModel(x);
        }
      });

      // Wait for loading all user details
      const usersWithDetails = await Promise.all(userDetailsPromises);

      return {
        items: usersWithDetails,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },

    itemsPerPage: 12,
  });

  get users() {
    return this._users;
  }

  async init(id: string) {
    const result = await this.questApi.adminQuest.getQuestById(id);
    const quest = new AdminQuestsModel(result.data);

    runInAction(() => {
      this._quest = quest;
    });

    this.users.fetchData([]);
  }

  async refresh() {
    if (!this.quest) {
      return;
    }

    return this.init(this.quest.id);
  }
}
