export enum SexType {
  NONE = '0',
  MALE = '1',
  FEMALE = '2',
}

const sexLabels: Record<SexType, string> = {
  [SexType.NONE]: '-',
  [SexType.MALE]: 'Male',
  [SexType.FEMALE]: 'Female',
};

export const getSexLabel = (sex?: string | null): string => {
  return sexLabels[sex as SexType] || '-';
};
