/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import DashboardSection from '../section';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useEffect, useMemo, useState } from 'react';
import { useBaseStores } from '../../../providers/BaseStoresProvider';
import FormSelect from '../../../components/form/form-select';

const DashboardAccountBalances = observer(() => {
  const intl = useIntl();
  const { accountsStore } = useBaseStores();
  const [accountId, setAccountId] = useState('mm');

  const accounts = accountsStore.accounts.map((id) => ({
    id: id,
    value: id,
    label: id,
  }));

  const loadBalances = useMemo(
    () => accountsStore.accountBalances.fetch,
    [accountsStore.accountBalances]
  );

  useEffect(() => {
    if (accountId) loadBalances(accountId);

    return () => {
      accountsStore.accountBalances.reset();
    };
  }, [loadBalances, accountId, accountsStore.accountBalances]);

  const balances = accountsStore.accountBalances.data || [];

  return (
    <DashboardSection
      title={intl.formatMessage({
        id: 'page.dashboard.accounts',
        defaultMessage: 'Accounts',
      })}
    >
      <div>
        <FormSelect
          showSearch={true}
          label={intl.formatMessage({
            id: 'page.dashboard.account_id',
            defaultMessage: 'Account ID',
          })}
          variant='borderless'
          options={accounts}
          placeholder={intl.formatMessage({
            id: 'page.dashboard.account_id',
            defaultMessage: 'Account ID',
          })}
          onChange={(value) => setAccountId(value?.value as string)}
          value={accounts.find((item) => item.value === accountId)}
        />
      </div>

      <div>
        {balances.length > 0 ? (
          <div className={styles.accounts_grid}>
            {balances.map((balance, index) => (
              <div key={index} className={styles.account}>
                <span className={styles.id}>{balance.account}</span>
                <h4>{parseFloat(balance.balance).toFixed(2)}</h4>
                <span>{balance.currency}</span>
              </div>
            ))}
          </div>
        ) : (
          <p>
            {intl.formatMessage({
              id: 'page.dashboard.balance.no_data',
              defaultMessage: 'No balance data available.',
            })}
          </p>
        )}
      </div>
    </DashboardSection>
  );
});

export default DashboardAccountBalances;
