/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useIntl } from 'react-intl';
import styles from './index.module.css';
import Button from 'components/core/button';

type Props = {
  onDelete: () => void;
};

const DeleteQuest = observer(({ onDelete }: Props) => {
  const intl = useIntl();
  const { layoutStore } = useBaseStores();

  const deleteQuest = async () => {
    onDelete();
    layoutStore.toggleModal(false);
  };

  return (
    <div className={styles.wrapper}>
      <span>
        {intl.formatMessage({
          id: 'page.quest_details.delete_quest_question',
          defaultMessage: `Are you sure you want to delete the current quest?`,
        })}
      </span>

      <div className={styles.btns}>
        <Button
          className={styles.btn}
          variant='secondary'
          onClick={() => layoutStore.toggleModal(false)}
        >
          {intl.formatMessage({
            id: 'page.quest_details.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <Button className={styles.btn} variant='primary' onClick={deleteQuest}>
          {intl.formatMessage({
            id: 'page.quest_details.delete_quest',
            defaultMessage: 'Delete quest',
          })}
        </Button>
      </div>
    </div>
  );
});

export const useDeleteQuest = () => {
  const intl = useIntl();
  const { layoutStore } = useBaseStores();

  const showDeleteQuestModal = useCallback(
    (onDelete: () => void) => {
      layoutStore.toggleModal(
        true,
        intl.formatMessage({
          id: 'page.user_details.modal.delete_document',
          defaultMessage: 'Delete Document',
        }),
        <DeleteQuest onDelete={onDelete} />
      );
    },
    [intl, layoutStore]
  );

  return {
    showDeleteQuestModal,
  };
};
