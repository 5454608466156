/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer } from 'mobx-react-lite';
import React from 'react';
import { ReactComponent as InfoIcon } from 'assets/images/icons/info.svg';
import { useIntl } from 'react-intl';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { TableProps, Tooltip } from 'antd';
import Table from '../core/table';
import { AdminQuestsModel } from 'models/AdminQuestModel';
import { formattedDate, FULL_DATE } from 'utils/dates';
import { BadgeStatus } from 'entities/quest';
import { cutId } from 'utils/strings';
import { QuestStep } from 'api/quests';

type Props = {
  collection: CollectionWithPages<AdminQuestsModel, {}>;
  onQuestClick?: (quest: AdminQuestsModel) => void;
  isUser?: boolean;
};

const QuestsTable = observer(({ collection, onQuestClick, isUser }: Props) => {
  const intl = useIntl();

  const columns: TableProps<AdminQuestsModel>['columns'] = [
    ...(!isUser
      ? []
      : [
          {
            title: intl.formatMessage({
              id: 'table.quests.col.id',
              defaultMessage: 'ID',
            }),
            dataIndex: 'id',
            key: 'id',
            render: (id: string) => <span>{cutId(id)}</span>,
            width: 20,
          },
        ]),
    {
      title: intl.formatMessage({
        id: 'table.quests.col.name',
        defaultMessage: 'Name',
      }),
      dataIndex: 'name',
      key: 'name',
      render: (name) => <span>{name || '-'}</span>,
      width: 20,
    },
    ...(!isUser
      ? [
          {
            title: intl.formatMessage({
              id: 'table.quests.col.total_reward',
              defaultMessage: 'Total reward pool',
            }),
            dataIndex: 'questSteps',
            key: 'questSteps',
            render: (questSteps: QuestStep[], record: AdminQuestsModel) => (
              <div className='flex items-center gap-1'>
                <span>
                  {questSteps
                    .reduce((acc, { reward }) => acc + Number(reward), 0)
                    .toFixed(2)}{' '}
                  {record.currency}
                </span>
                <Tooltip
                  title={
                    <div className='flex flex-col gap-[2px]'>
                      {questSteps.map(({ reward, name }) => (
                        <div className='flex gap-1' key={name}>
                          <span>{name}</span> -{' '}
                          <span>
                            {reward} {record.currency}
                          </span>
                        </div>
                      ))}
                    </div>
                  }
                >
                  <InfoIcon width={18} color='#6a6b7a' />
                </Tooltip>
              </div>
            ),
            width: 100,
          },
        ]
      : [
          {
            title: intl.formatMessage({
              id: 'table.quests.col.events',
              defaultMessage: 'Events',
            }),
            dataIndex: 'events',
            key: 'events',
            render: (events: string[]) => <span>{events?.length || 0}</span>,
            width: 20,
          },
        ]),
    {
      title: intl.formatMessage({
        id: 'table.quests.col.start',
        defaultMessage: 'Start',
      }),
      dataIndex: 'startedAt',
      key: 'startedAt',
      render: (startedAt) => <span>{formattedDate(startedAt, FULL_DATE)}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.quests.col.finish',
        defaultMessage: 'Finish',
      }),
      dataIndex: 'expiredAt',
      key: 'expiredAt',
      render: (expiredAt) => <span>{formattedDate(expiredAt, FULL_DATE)}</span>,
      width: 20,
    },
    ...(!isUser
      ? [
          {
            title: intl.formatMessage({
              id: 'table.quests.col.active_users',
              defaultMessage: 'Active users',
            }),
            dataIndex: 'activeUsers',
            key: 'activeUsers',
            render: (activeUsers: string) => <span>{activeUsers}</span>,
            width: 20,
          },
          {
            title: intl.formatMessage({
              id: 'table.quests.col.cpi',
              defaultMessage: 'CPI',
            }),
            dataIndex: 'cpi',
            key: 'cpi',
            render: (cpi: string) => <span>${cpi}</span>,
            width: 20,
          },
          {
            title: intl.formatMessage({
              id: 'table.quests.col.total_revenue',
              defaultMessage: 'Total revenue',
            }),
            dataIndex: 'totalRevenue',
            key: 'totalRevenue',
            render: (totalRevenue: string) => <span>${totalRevenue}</span>,
            width: 20,
          },
          {
            title: intl.formatMessage({
              id: 'table.quests.col.completed_rate',
              defaultMessage: 'Completed rate',
            }),
            dataIndex: 'completedRate',
            key: 'completedRate',
            render: (completedRate: string) => <span>{completedRate}</span>,
            width: 20,
          },
          {
            title: intl.formatMessage({
              id: 'table.quests.col.budget',
              defaultMessage: 'Budget',
            }),
            dataIndex: 'budget',
            key: 'budget',
            render: (budget: string) => <span>${budget}</span>,
            width: 20,
          },
        ]
      : []),
    {
      title: intl.formatMessage({
        id: 'table.subscriptions.col.status',
        defaultMessage: 'Status',
      }),
      dataIndex: 'status',
      key: 'status',
      render: (status) => <BadgeStatus status={status} />,
      width: 50,
    },
  ];

  return (
    <Table<AdminQuestsModel, {}>
      collection={collection}
      columns={columns}
      onRowClick={onQuestClick}
    />
  );
});

export default QuestsTable;
