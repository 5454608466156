/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useFormContext } from 'react-hook-form';
import { CreateQuestForm } from '../index';
import { useEffect } from 'react';
import { TimerType } from '../../../../api/quests';
import { transformFromBackendTimer } from '../../../../utils/dates';

export const useTimerTypeSynchronization = () => {
  const { setValue, watch } = useFormContext<CreateQuestForm>();

  const [timerType, timer, events] = watch(['timerType', 'timer', 'events']);

  useEffect(() => {
    switch (timerType.value) {
      case TimerType.UNIVERSAL:
        // Synchronize events when UNIVERSAL
        const synchronizedEvents = events.map((event) => {
          if (event.exclusive || event.isInstall) {
            return {
              ...event,
              timer: event.isInstall ? undefined : event.timer,
            };
          }
          return { ...event, timer: timer };
        });

        // Only update if there are actual changes
        const hasChanges = synchronizedEvents.some(
          (syncEvent, index) => syncEvent.timer !== events[index].timer
        );

        if (hasChanges) {
          setValue('events', synchronizedEvents, {
            shouldValidate: true,
          });
        }
        break;

      case TimerType.SEPARATE:
        // Reset universal timer to undefined
        setValue('timer', transformFromBackendTimer(undefined).value as any, {
          shouldValidate: true,
          shouldDirty: true,
        });

        // Reset non-exclusive event timers
        /*const resetEvents = events.map((event) =>
          event.exclusive ? event : { ...event, timer: undefined }
        );

        setValue('events', resetEvents, {
          shouldValidate: true,
        });*/
        break;

      default:
        // Optionally handle any unexpected timer type
        console.warn('Unexpected timer type:', timerType.value);
    }
  }, [timerType, timer, events, setValue]);
};
