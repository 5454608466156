/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';

import { observer } from 'mobx-react-lite';
import {
  ProviderQuestDetailsPageStore,
  useQuestDetailsPageStore,
} from './provider/StoreProvider';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from 'components/elements/back-button';
import { ROUTES } from 'routes';
import { useSidebarCreateQuest } from 'components/sidebars/sidebar-quest-create';
import Button from 'components/core/button';
import { MdEdit } from 'react-icons/md';
import { QuestInfo } from './info';
import { Users } from './users';
import { Events } from './events';

const QuestDetailsPageWithProvider = observer(() => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();
  const navigate = useNavigate();
  const store = useQuestDetailsPageStore();

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.loan_details.Quest',
        defaultMessage: 'Quest',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  const { showCreateQuestSidebar } = useSidebarCreateQuest({
    quest: store.quest || undefined,
    onCreate: (value) => store.updateQuest(value),
    onDelete: (questId) => {
      store.deleteQuest(questId);
      navigate(ROUTES.quests.fullPath);
    },
  });

  if (!store.quest) {
    return null;
  }

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <BackButton fallbackUrl={ROUTES.quests.fullPath} />
        <Button variant='secondary' onClick={showCreateQuestSidebar}>
          <span>
            {intl.formatMessage({
              id: 'page.quests.edit_quest',
              defaultMessage: 'Edit quest',
            })}
          </span>

          <MdEdit />
        </Button>
      </div>

      <QuestInfo />
      <Events events={store.quest.questSteps || []} />
      <Users collection={store.users} />
    </div>
  );
});

const QuestDetailsPage = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <ProviderQuestDetailsPageStore id={id}>
      <QuestDetailsPageWithProvider />
    </ProviderQuestDetailsPageStore>
  );
};

export default QuestDetailsPage;
