import { TableProps } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { cutId } from 'utils/strings';
import styles from './index.module.css';
import { QuestStep } from 'api/quests';
import TableSimple from 'components/core/table-simple';

type EventsProps = {
  events: QuestStep[];
};

export const Events = ({ events }: EventsProps) => {
  const intl = useIntl();

  const columns: TableProps<any>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.quests.col.event_key',
        defaultMessage: 'Event key',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id) => <span>{cutId(id)}</span>,
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.quests.col.event_name',
        defaultMessage: 'Event name',
      }),
      dataIndex: 'name',
      key: 'name',
      render: (name) => {
        return <span>{name}</span>;
      },
      width: 200,
    },

    {
      title: intl.formatMessage({
        id: 'table.quests.col.event_completed',
        defaultMessage: 'Event completed',
      }),
      dataIndex: 'completedSteps',
      key: 'completedSteps',
      render: (_, record) => {
        return (
          <span>
            {record.completedSteps} (
            {record.startedSteps
              ? Math.floor((record.completedSteps / record.startedSteps) * 100)
              : 0}
            %)
          </span>
        );
      },
      width: 200,
    },

    /*{
      title: intl.formatMessage({
        id: 'table.quests.col.event_started',
        defaultMessage: 'Event started',
      }),
      dataIndex: 'startedSteps',
      key: 'startedSteps',
      render: (startedSteps) => {
        return <span>{startedSteps}</span>;
      },
      width: 20,
    },*/

    /*{
      title: intl.formatMessage({
        id: 'table.quests.col.event_completed',
        defaultMessage: 'Event completed',
      }),
      dataIndex: 'completedSteps',
      key: 'completedSteps',
      render: (completedSteps) => {
        return <span>{completedSteps}</span>;
      },
      width: 20,
    },*/

    // {
    //   title: intl.formatMessage({
    //     id: 'table.quests.col.event_completed',
    //     defaultMessage: 'Event completed',
    //   }),
    //   dataIndex: 'name',
    //   key: 'name',
    //   render: (name) => <span>456 (95%)</span>,
    //   width: 20,
    // },
    // {
    //   title: intl.formatMessage({
    //     id: 'table.quests.col.start',
    //     defaultMessage: 'Start',
    //   }),
    //   dataIndex: 'startedAt',
    //   key: 'startedAt',
    //   render: (name) => <span>2h 56m</span>,
    //   width: 20,
    // },
    // {
    //   title: intl.formatMessage({
    //     id: 'table.quests.col.created',
    //     defaultMessage: 'Created',
    //   }),
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   render: (name) => <span>04/23/2024</span>,
    //   width: 20,
    // },
  ];

  return (
    <div className={styles.main}>
      <h3>
        {intl.formatMessage({
          id: 'page.quest.events',
          defaultMessage: 'Events',
        })}
      </h3>
      <TableSimple collection={events} columns={columns} size='large' />
    </div>
  );
};
